@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Montserrat, system-ui, sans-serif;
    font-size: 0.875rem;
  }
}
